/*Jost* is free for commercial as well as */
/*personal use*/
/*find out more at http://indestructibletype.com/Jost.html*/
/*Only two weight are selected for demo if you need */
/*more weights please refer jost-complete.css this directory*/

@font-face {
    font-family: 'Jost*';
    src: url('jost-600-semi.woff2') format('woff2'),
         url('jost-600-semi.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'Jost*';
    src: url('jost-500-medium.woff2') format('woff2'),
         url('jost-500-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}